@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
   url("./fonts/Helvetica-Font/Helvetica-Bold.ttf") format("truetype");
  }

body {
  margin: 0px !important;
  font-family: "Helvetica";
  
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.reveal {
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

